<template>
  <div class="bg-primary vh-100 p-5 d-flex flex-column overflow-auto">
    <div class="logo">
      <img src="@/assets/icons/Logo.svg" />
    </div>
    <div class="mt-auto">
      <div class="text-center m-auto w-650 mt-auto">
        <h3>Login</h3>
        <p class="pt-3 pb-5">
          Für Ihren Zugang brauchen Sie ein Microsoft-Konto. Drücken Sie auf
          »Weiter zur Aktivierung« und folgen Sie den Anweisungen. Nach der
          Registrierung werden Sie für den Service freigeschaltet. Dies kann bis
          zu einem Tag dauern.
        </p>
      </div>
    </div>
    <div class="text-center mb-auto">
      <button class="btn btn-white" @click="login">
        Weiter zur Akivierung
      </button>
    </div>
  </div>
</template>


<script>
export default {
  name: "Login",
  methods: {
    login() {
      this.$msal.signIn();
    },
  },
  mounted() {
    if (this.$msal.isAuthenticated()) this.$router.push({ name: "Home" });
  },
};
</script>